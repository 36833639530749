import React, { useState } from 'react'; 
import { 
  Typography, 
  Box, 
  Container, 
  Grid, 
  // eslint-disable-next-line no-unused-vars
  IconButton,
  Paper,
  Button
} from '@mui/material';
import { styled } from '@mui/system';
// eslint-disable-next-line no-unused-vars
import { motion } from 'framer-motion';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SecurityIcon from '@mui/icons-material/Security';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from 'react-router-dom';

// Import images
import caps from "../assets/products/caps.jpg";
import hoodies from "../assets/products/hoodies.jpg";
import tshirts from "../assets/products/tshirts.jpg";
import sweatbands from "../assets/products/sweatbands.jpg";
import socks from "../assets/products/socks.jpg";
import comingSoon from "../assets/products/coming-soon.jpg";
import jeans from "../assets/latest/classic-jeans.jpeg";
import ladies from "../assets/categories/ladies/shortSkirt.jpeg";
import croptee from "../assets/categories/croptee/cropTeeCosmos.jpeg";
import hat from "../assets/categories/hat/Hat.jpeg";
import singlet from "../assets/categories/singlet/singlet.jpeg";

// Styled components
const ProductCard = styled(Paper)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: theme.spacing(2),
  height: '100%',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 12px 24px rgba(0, 0, 0, 0.15)',
  }
}));

const ProductImage = styled('img')({
  width: '100%',
  height: '300px',
  objectFit: 'cover',
  transition: 'transform 0.5s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  }
});

const ProductOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
  '&:hover': {
    opacity: 1,
  }
}));

const AnimatedCircle = styled(Box)(({ color, delay }) => ({
  position: 'absolute',
  width: 60,
  height: 60,
  borderRadius: '50%',
  backgroundColor: color,
  opacity: 0.5,
  animation: `ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite ${delay}s`,
  '@keyframes ping': {
    '75%, 100%': {
      transform: 'scale(2)',
      opacity: 0,
    },
  },
}));

const FeatureCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  height: '100%',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-4px)',

  }
}));

const ProductShowcase = () => {
  const navigate = useNavigate();
  const [hoveredId, setHoveredId] = useState(null);

  // Define product data with colors
  const products = [
    { id: 1, title: "Caps", image: caps, colors: ["#FF6B6B", "#4ECDC4", "#45B7D1"] },
    { id: 2, title: "Hoodies", image: hoodies, colors: ["#96CEB4", "#FFEEAD", "#D4A5A5"] },
    { id: 3, title: "T-Shirts", image: tshirts, colors: ["#9B59B6", "#3498DB", "#2ECC71"] },
    { id: 4, title: "Sweatbands", image: sweatbands, colors: ["#E74C3C", "#F1C40F", "#2980B9"] },
    { id: 5, title: "Socks", image: socks, colors: ["#1ABC9C", "#D35400", "#8E44AD"] },
    { id: 6, title: "Sweatshirts", image: comingSoon, colors: ["#27AE60", "#E67E22", "#2C3E50"] },
    { id: 7, title: "Jeans", image: jeans, colors: ["#3498DB", "#E74C3C", "#F39C12"] },
    { id: 8, title: "Ladies-wears", image: ladies, colors: ["#9B59B6", "#16A085", "#D35400"] },
    { id: 9, title: "Crop-Tee", image: croptee, colors: ["#2ECC71", "#E67E22", "#8E44AD"] },
    { id: 10, title: "Rugged-Hat", image: hat, colors: ["#F1C40F", "#2980B9", "#C0392B"] },
    { id: 11, title: "Singlet", image: singlet, colors: ["#16A085", "#8E44AD", "#D35400"] }
  ];

  const features = [
    { 
      icon: LocalShippingIcon, 
      title: "Free Shipping", 
      description: "Free shipping on orders above ₦50,000" 
    },
    { 
      icon: SecurityIcon, 
      title: "Secure Shopping", 
      description: "100% secure payment processing" 
    },
    { 
      icon: AssignmentReturnIcon, 
      title: "Easy Returns", 
      description: "30-day hassle-free returns" 
    },
    { 
      icon: StarIcon, 
      title: "Quality Products", 
      description: "Handpicked premium items" 
    }
  ];

  return (
    <Box sx={{ bgcolor: '#f5f5f5', py: 8 }}>
      <Container maxWidth="lg">
        <Typography 
          variant="h3" 
          component="h2" 
          align="center" 
          sx={{ 
            mb: 6, 
            fontWeight: 500,
            color: (theme) => theme.palette.text.primary,
            textTransform: 'uppercase',
            letterSpacing: '0.05em'
          }}
        >
          Shop by Category
        </Typography>

        <Grid container spacing={4}>
          {products.map((product) => (
            <Grid item xs={12} sm={6} md={4} key={product.id}>
              <ProductCard
                elevation={2}
                onMouseEnter={() => setHoveredId(product.id)}
                onClick={() => setHoveredId(product.id)}
                onMouseLeave={() => setHoveredId(null)}
              >
                <ProductImage 
                  src={product.image}
                  alt={product.title}
                />
                
                {hoveredId === product.id && product.colors.map((color, index) => (
                  <AnimatedCircle
                    key={color}
                    color={color}
                    delay={index * 0.2}
                    sx={{
                      top: `${20 + index * 30}%`,
                      left: `${20 + index * 30}%`,
                    }}
                  />
                ))}

                <ProductOverlay>
                  <Typography 
                    variant="h5" 
                    sx={{ 
                      color: 'white',
                      mb: 2,
                      fontWeight: 600,
                      textTransform: 'uppercase',
                      letterSpacing: '0.1em'
                    }}
                  >
                    {product.title}
                  </Typography>

                  <Button
                    variant="contained"
                    startIcon={<ShoppingCartIcon />}
                    onClick={() => navigate(`/${product.title.toLowerCase()}`)}
                    sx={{
                      borderRadius: 25,
                      px: 4,
                      py: 1,
                      backgroundColor: 'white',
                      color: 'text.primary',
                      fontWeight: 500,
                      '&:hover': {
                        backgroundColor: 'grey.100',
                        transform: 'scale(1.05)',
                      },
                      transition: 'all 0.2s ease'
                    }}
                  >
                    Shop Now
                  </Button>
                </ProductOverlay>
              </ProductCard>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: 12 }}>
          <Grid container spacing={4}>
            {features.map((feature) => {
              const Icon = feature.icon;
              return (
                <Grid item xs={12} sm={6} md={3} key={feature.title}>
                  <FeatureCard elevation={1}>
                    <Icon 
                      sx={{ 
                        fontSize: 48, 
                        color: 'primary.main',
                        mb: 2 
                      }} 
                    />
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        mb: 1,
                        fontWeight: 600,
                        letterSpacing: '0.05em'
                      }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                    >
                      {feature.description}
                    </Typography>
                  </FeatureCard>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default ProductShowcase;